import React from "react"
import clsx from "clsx"

export const Checkbox: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  id,
  children,
  ...props
}) => {
  return (
    <div className={clsx("checkbox", className)}>
      <input type="checkbox" {...props} id={id} />
      <label htmlFor={id}>{children}</label>
    </div>
  )
}
