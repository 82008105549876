import React from "react"
import clsx from "clsx"
import { ButtonType } from "./types"
import { Spinner } from "../misc"

export interface RegularButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  isSubmitting?: boolean
  disabled?: boolean
  isFormik?: boolean
  variant?: ButtonType
  textClassName?: string
  customPadding?: string
  customTextSize?: string
}

export const RegularButton: React.FC<RegularButtonProps> = ({
  isSubmitting,
  textClassName,
  children,
  variant = "solid",
  customPadding = "px-5 py-2",
  customTextSize = "sm-only:text-sm",
  className,
  isFormik,
  ...props
}) => {
  let buttonStyle = ""

  switch (true) {
    case variant === "solid":
      buttonStyle = "text-white bg-green"
      break
    case variant === "transparent":
      buttonStyle = "text-green bg-transparent border-green border border-2"
      break
    case variant === "transparent-white":
      buttonStyle = "text-white bg-transparent border-white border border-2"
      break
    case variant === "transparent-black":
      buttonStyle = "text-black bg-transparent border-black border border-2"
      break
    case variant === "blue":
      buttonStyle = "text-white bg-blue border-blue"
      break
    case variant === "link-transparent":
      buttonStyle = "text-black bg-transparent"
      break
    default:
      break
  }

  if (variant === "link-transparent") {
    return (
      <button
        {...props}
        className={clsx(
          "relative flex items-center justify-center text-black-48 hover:text-green",
          buttonStyle,
          customPadding,
          customTextSize,
          className
        )}
        type="button"
      >
        <span>{children}</span>
      </button>
    )
  }

  return (
    <button
      {...props}
      className={clsx(
        "relative flex items-center justify-center rounded lg:font-semibold font-medium hover:shadow-[0_12px_32px_0px_rgba(0,0,0,0.2)]",
        "disabled:bg-grey-97 disabled:text-white disabled:cursor-not-allowed",
        buttonStyle,
        customPadding,
        customTextSize,
        className
      )}
      type={isFormik ? "submit" : "button"}
    >
      {isSubmitting && <Spinner size="sm" className="" />}
      <span className={clsx(isSubmitting && "text-transparent", textClassName)}>{children}</span>
    </button>
  )
}
