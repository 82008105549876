import React, { FC, InputHTMLAttributes, SyntheticEvent, useState } from "react"
import { useField } from "formik"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import clsx from "clsx"

interface FocusEvent<T = Element> extends SyntheticEvent<T> {
  relatedTarget: EventTarget | null
  target: EventTarget & T
}

export const PasswordInput = (props: InputHTMLAttributes<HTMLInputElement>) => {
  // @ts-ignore
  const [field, meta] = useField(props.name)

  const [showPassword, setShowPassword] = useState(false)
  const [isFocused, setIsFocused] = useState(false)

  const errorText = meta.error

  const passwordToggle = () => {
    setShowPassword(!showPassword)
  }

  const handleBlur = async (e: FocusEvent<HTMLInputElement>) => {
    field.onBlur(e)
    setIsFocused(false)
  }

  function classNameSwitcher() {
    const passwordClass = "flex items-center py-2.5 px-3 rounded border border-grey-ec"

    switch (true) {
      case isFocused:
      case field.value && !errorText:
        return `${passwordClass} text-grey-69`
      case errorText !== undefined:
        return `${passwordClass} !border-red text-red`

      default:
        return passwordClass
    }
  }

  return (
    <div className={clsx("relative", props.className)}>
      <div className={classNameSwitcher()}>
        <input
          {...field}
          {...props}
          className={clsx("w-full", "focus:outline-none focus:shadow-outline")}
          type={showPassword ? "text" : "password"}
          placeholder={props.placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={handleBlur}
        />
        <div className="w-[10%] flex justify-end" onClick={passwordToggle}>
          {showPassword ? (
            <AiOutlineEyeInvisible className="cursor-pointer" />
          ) : (
            <AiOutlineEye className="cursor-pointer" />
          )}
        </div>
      </div>
      <small className="text-red">{errorText}</small>
    </div>
  )
}
