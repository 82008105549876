import React from "react"
import clsx from "clsx"

export const Radio: React.FC<React.InputHTMLAttributes<HTMLInputElement>> = ({
  className,
  id,
  children,
  ...props
}) => {
  return (
    <div className={clsx("radio", className)}>
      <input type="radio" id={id} {...props} />
      <label htmlFor={id}>{children}</label>
    </div>
  )
}
