import { FC, useState } from "react"
import GoogleLogin from "react-google-login"
import { Spinner } from "../misc"
import clsx from "clsx"
import AuthServices from "@lib/api/services/auth.services"
import Image from "next/image"

export interface GoogleLoginProps {
  redirect?: string
  handleError: (a: string) => void
}

export const GoogleLoginButton: FC<GoogleLoginProps> = ({ redirect, handleError }) => {
  const [isSubmitting, setIsSubmitting] = useState(false)

  const auth = new AuthServices()

  async function handleGoogleLogin(response: any) {
    const userProfile = response.profileObj
    const userFullname = userProfile.name
    const userEmail = userProfile.email
    const googleId = userProfile.googleId

    setIsSubmitting(true)

    try {
      handleError("")

      const res = await auth.googleLogin({
        email: userEmail,
        fullName: userFullname,
        googleId,
      })

      if (res.isFailure) {
        setIsSubmitting(false)
        const errorMessage = res.error.message
        handleError(errorMessage)
      } else {
        const result = res.getValue()

        if (result.error_code === "SUCCESS") {
          if (!redirect) {
            window.location.reload()
          } else {
            window.location.assign(redirect)
          }
        } else {
          window.location.assign("/biodata")
        }
      }
    } catch (error: any) {
      setIsSubmitting(false)
      handleError("Terjadi kesalahan. Silahkan coba lagi")
    }
  }

  function handleGoogleError(res: any): void {
    console.log(res)
    // console.log(res)
  }

  return (
    <GoogleLogin
      clientId={process.env.GOOGLE_CLIENT_ID || process.env.HARISENIN_GOOGLE_CLIENT_ID || ""}
      render={(renderProps) => (
        <button
          disabled={isSubmitting}
          onClick={renderProps.onClick}
          className={clsx(
            "flex items-center justify-center relative px-4 py-[22px] h-[50px] text-grey-69 w-full border border-grey-69 rounded"
          )}
        >
          <>
            {isSubmitting ? (
              <Spinner />
            ) : (
              <>
                <Image
                  src="https://pngimg.com/uploads/google/google_PNG19635.png"
                  alt="google"
                  className="h-7 absolute left-[10%]"
                  width={28}
                  height={28}
                />
                <span>Lanjutkan dengan Google</span>
              </>
            )}
          </>
        </button>
      )}
      buttonText="Login"
      onSuccess={handleGoogleLogin}
      onFailure={handleGoogleError}
      cookiePolicy={"single_host_origin"}
    />
  )
}
