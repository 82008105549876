import React from "react"
import { useScreenSize } from "@lib/hooks"
import clsx from "clsx"
import { FlexBox } from "@components/wrapper"
import { HARISENIN_LOGO, HARISENIN_LOGO_WHITE } from "@const/pictures"
import { HeaderComponent } from "./components"
import Image from "next/image"
import Link from "next/link";

interface HeaderProps {
  transparent?: boolean
  isNotSticky?: boolean
  bgOnly?: boolean
}

export function Header({ transparent, isNotSticky, bgOnly }: HeaderProps) {
  const { deviceType } = useScreenSize()
  return (
    <>
      {deviceType === "web" ? (
        <div
          className={clsx(
            "top-0 z-[1000]",
            isNotSticky ? "fixed" : "sticky",
            transparent
              ? `w-full fixed transition ease-in delay-100 ${
                  !bgOnly && "text-white"
                } bg-transparent`
              : "bg-white w-full"
          )}
        >
          {/* Header Component */}
          <FlexBox align="center" justify="between" className="px-20 py-4">
            {/* Logo */}
            <FlexBox align="center">
              <Link href="/" passHref={true}>
                <Image
                  src={transparent && !bgOnly ? HARISENIN_LOGO_WHITE : HARISENIN_LOGO}
                  alt="harisenin.com logo"
                  className="h-[25px]"
                  height={50}
                  width={200}
                />
              </Link>
            </FlexBox>

            <HeaderComponent />
          </FlexBox>
        </div>
      ) : (
        <>
          {/* Header Component */}
          <FlexBox
            align="center"
            justify="center"
            className={clsx(
              "px-20 py-4 ",
              "top-0 z-[1000] bg-white",
              isNotSticky ? "fixed" : "sticky"
            )}
          >
            {/* Logo */}
            <FlexBox align="center">
              <Link href="/" passHref={true}>
                <Image
                  src={transparent && !bgOnly ? HARISENIN_LOGO_WHITE : HARISENIN_LOGO}
                  alt="harisenin.com logo"
                  className="h-[20px]"
                  height={50}
                  width={200}
                />
              </Link>
            </FlexBox>
          </FlexBox>
        </>
      )}
    </>
  )
}
