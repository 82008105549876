import React from "react"
import { ButtonType } from "./types"
import clsx from "clsx"
import Link from "next/link"

export interface LinkButtonProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: ButtonType
  isExternal?: boolean
  isNewTab?: boolean
  customPadding?: string
  customFontSize?: string
}

export const LinkButton: React.FC<LinkButtonProps> = ({
  variant = "solid",
  isExternal = false,
  isNewTab = false,
  href = "",
  customPadding = "px-5 py-2",
  customFontSize = "text-base-responsive",
  ...props
}) => {
  let variantStyle = ""

  switch (true) {
    case variant === "solid":
      variantStyle = "text-white bg-green hover:text-white"
      break
    case variant === "transparent":
      variantStyle = "text-green bg-transparent border-green lg:border border-2"
      break
    case variant === "transparent-white":
      variantStyle = "text-green bg-grey-ec"
      break
    case variant === "blue":
      variantStyle = "text-white bg-blue border-blue hover:text-white"
      break
    default:
      break
  }

  const styles = clsx(
    "relative flex items-center justify-center rounded lg:font-semibold font-medium hover:shadow-[0_12px_32px_0px_rgba(0,0,0,0.2)]",
    variantStyle,
    customPadding,
    customFontSize,
    props.className
  )

  return (
    <React.Fragment>
      {isExternal ? (
        <a
          {...props}
          href={href}
          className={styles}
          target={isNewTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
        />
      ) : (
        <Link href={href} {...props} className={styles} />
      )}
    </React.Fragment>
  )
}
