import { useEffect, useRef } from "react"

export function useInterval(callback: () => void | null, delay: number | null) {
  const savedCallback = useRef(callback)

  // Remember the latest callback if it changes.
  // useLayoutEffect(() => {
  //   savedCallback.current = callback
  // }, [callback])

  // Set up the interval.
  useEffect(() => {
    // Don't schedule if no delay is specified.
    // Note: 0 is a valid value for delay.
    if (!delay && delay !== 0) {
      return
    }

    savedCallback.current = callback

    const id = setInterval(() => savedCallback.current(), delay)

    return () => {
      clearInterval(id)
      // @ts-ignore
      savedCallback.current = null
    }
  }, [delay, callback])
}
