import React, { FC, InputHTMLAttributes } from "react"
import { useField } from "formik"
import clsx from "clsx"

export interface FormikInputProps extends InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string
  label?: string
  isNumber?: boolean
  name: string
  required?: boolean
  className?: string
  inputClassName?: string
}

export const FormikInput: FC<FormikInputProps> = ({
  label,
  name,
  isNumber,
  className,
  inputClassName,
  required,
  ...props
}) => {
  const [field, meta] = useField(name)
  const errorText = meta.error

  return (
    <div className={clsx("relative", className)}>
      <input
        type={isNumber ? "number" : "text"}
        {...field}
        {...props}
        className={clsx(
          inputClassName,
          "py-2.5 px-3 rounded w-full border-2 border-grey-ec lg:text-base",
          "focus:outline-none focus:shadow-outline focus:border-green active:shadow-outline active:border-green text-sm",
          field.value && !errorText && "border-2 !border-green",
          errorText && "!border-red border"
        )}
      />
      <small className="text-red absolute -bottom-4 left-0">{errorText}</small>
    </div>
  )
}
