import { CareerCategory } from "@interfaces/career"
import { Result } from "@lib/api/services/result"
import CategoryRepositories from "../repositories/category.repositories"

export default class CategoryServices extends CategoryRepositories {
  async getCategoryList() {
    try {
      const res = await this.getRawCategories()
      const response = await this._getResponse<CareerCategory[]>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<CareerCategory[]>()
      }

      return Result.ok<CareerCategory[]>(result)
    } catch (e: any) {
      return Result.fail<CareerCategory[]>()
    }
  }

  async getTools() {
    try {
      const res = await this.getRawTool()
      const response = await this._getResponse<any>(res)
      const { error, result } = response
      if (error) {
        Result.fail<any>()
      }
      return Result.ok<any>(result)
    } catch (e: any) {
      console.log(e)
      Result.fail<any>()
    }
  }

  async getSkills() {
    try {
      const res = await this.getRawSkill()
      const response = await this._getResponse<any>(res)
      const { error, result } = response
      if (error) {
        Result.fail<any>()
      }
      return Result.ok<any>(result)
    } catch (e: any) {
      console.log(e)
      Result.fail<any>()
    }
  }

  async getCategoryDetail(category_name: string) {
    try {
      const res = await this.getRawCategoryDetail(category_name)
      const response = await this._getResponse<CareerCategory>(res)

      const { error, result } = response

      if (error) {
        return Result.fail<CareerCategory>()
      }

      return Result.ok<CareerCategory>(result)
    } catch (e: any) {
      return Result.fail<CareerCategory>()
    }
  }
}
