import React from "react"
import { FlexBox } from "@components/wrapper"
import { RegularButton } from "@components/button"
import { BasicLink } from "@components/typography"
import { useRouter } from "next/router"

export const HeaderComponent = () => {
  const router = useRouter()

  const executeScroll = (id: string) => async () => {
    const ele = document.getElementById(id)

    if (router.pathname != "/") {
      await router.push("/")
    }

    if (!ele) {
      return
    }

    ele.scrollIntoView({ behavior: "smooth" })
  }

  return (
    <nav className="flex items-center justify-between">
      <FlexBox align="center" justify="between" className="gap-8">
        <RegularButton
          variant="link-transparent"
          className="font-medium"
          onClick={executeScroll("core-values")}
        >
          Core Values
        </RegularButton>

        <RegularButton
          variant="link-transparent"
          className="font-medium"
          onClick={executeScroll("category-column")}
        >
          Semua Pekerjaan
        </RegularButton>

        <BasicLink className="font-medium" href="/form/pool">
          Talent Pool
        </BasicLink>
      </FlexBox>
    </nav>
  )
}
