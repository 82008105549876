import { FC, InputHTMLAttributes } from "react"
import clsx from "clsx"

export interface TextInputProps extends InputHTMLAttributes<any> {
  errorText?: string
  inputClassName?: string
  customPadding?: string
}

export const TextInput: FC<TextInputProps> = ({
  errorText,
  value,
  className,
  inputClassName,
  customPadding = "py-2.5 px-3",
  ...props
}) => {
  return (
    <div className={clsx("relative", className)}>
      <input
        {...props}
        className={clsx(
          inputClassName,
          customPadding,
          "rounded w-full sm:text-base text-sm",
          "focus:outline-none focus:shadow-outline",
          errorText && "border-red border"
        )}
      />
      <small className="text-red">{errorText}</small>
    </div>
  )
}
