import Link from "next/link"

interface BasicLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  isNewTab?: boolean
  isExternal?: boolean
}

export const BasicLink: React.FC<BasicLinkProps> = ({
  children,
  href = "",
  isNewTab = false,
  isExternal = false,
  className,
  ...rest
}: BasicLinkProps) => {
  if (!isExternal && !isNewTab) {
    return (
      <Link href={href} {...rest} className={className}>
        {children}
      </Link>
    )
  }

  return (
    <a
      target={isNewTab ? "_blank" : "_self"}
      rel="noopener noreferrer"
      href={href}
      {...rest}
      className={className}
    >
      {children}
    </a>
  )
}
