import KyMethod from "./KyMethod"

export default class CategoryRepositories extends KyMethod {
  protected async getRawCategories() {
    return this._getData("/career/categories")
  }

  async getRawCategoryDetail(category_name: string) {
    return this._getData(`/career/categories/${category_name}`)
  }

  protected async getRawSkill() {
    return this._getData("/career/skills")
  }

  protected async getRawTool() {
    return this._getData("/career/tools")
  }
}
