import React, { FC, ReactNode } from "react"
import clsx from "clsx"

export interface FormikRadioInputProps {
  onChanges: (field: string, value: any, validate?: boolean) => void
  name: string
  id: any
  value: any
  fieldValue: any
  className?: string
  children: ReactNode
}

export const FormikRadioInput: FC<FormikRadioInputProps> = ({
  id,
  name,
  onChanges,
  value,
  className,
  fieldValue,
  children,
}) => {
  const handleChange = () => {
    onChanges(name, value)
  }

  return (
    <div className={clsx("radio_wrapper", className)}>
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        onChange={handleChange}
        checked={fieldValue === value}
      />
      <label htmlFor={id} onClick={() => handleChange}>
        {children}
      </label>
    </div>
  )
}
